// BOOTSTRAP 5 VARIABLES

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "components/bootstrap5/functions";

// 2. Include any default variable overrides here

@import "themes/layout-variables";

// 3. Include remainder of required Bootstrap stylesheets

@import "components/bootstrap5/variables";
@import "components/bootstrap5/mixins";
@import "components/bootstrap5/utilities";

// BOOTSTRAP 5 IMPORTS

@import "components/bootstrap5/root";
@import "components/bootstrap5/reboot";
@import "components/bootstrap5/type";
@import "components/bootstrap5/images";
@import "components/bootstrap5/containers";
@import "components/bootstrap5/grid";
@import "components/bootstrap5/tables";
@import "components/bootstrap5/forms";
@import "components/bootstrap5/buttons";
@import "components/bootstrap5/transitions";
@import "components/bootstrap5/dropdown";
@import "components/bootstrap5/button-group";
@import "components/bootstrap5/nav";
@import "components/bootstrap5/navbar";
@import "components/bootstrap5/card";
@import "components/bootstrap5/accordion";
@import "components/bootstrap5/breadcrumb";
@import "components/bootstrap5/pagination";
@import "components/bootstrap5/badge";
@import "components/bootstrap5/alert";
@import "components/bootstrap5/progress";
@import "components/bootstrap5/list-group";
@import "components/bootstrap5/close";
@import "components/bootstrap5/toasts";
@import "components/bootstrap5/modal";
@import "components/bootstrap5/tooltip";
@import "components/bootstrap5/popover";
@import "components/bootstrap5/carousel";
@import "components/bootstrap5/spinners";
@import "components/bootstrap5/offcanvas";
@import "components/bootstrap5/jumbotron";
@import "components/bootstrap5/helpers";
@import "components/bootstrap5/utilities/api";
// LAYOUT

@import "layout/layout";

// UTILS

@import "utils/helpers";
@import "utils/backgrounds";
@import "utils/animate";
@import "utils/comps-animations";

// ELEMENTS

@import "elements/buttons";
@import "elements/dropdown";
@import "elements/navs";
@import "elements/badges";
@import "elements/cards";
@import "elements/tabs";
@import "elements/accordions";
@import "elements/modals";
@import "elements/loaders";
@import "elements/progressbar";
@import "elements/timeline";
@import "elements/listgroup";
@import "elements/forms";
@import "elements/pagination";
@import "elements/chat";
@import "elements/forms-wizard";

// DASHBOARD BOXES

@import "widgets/chart-boxes/chart-boxes";
@import "widgets/content-boxes/content-boxes";
@import "widgets/profile-boxes/profile-boxes";

// PAGES

@import "pages/userpages";

// Applications

@import "applications/applications-base";

// COMPONENTS

// Perfect Scrollbar
@import "components/perfect-scrollbar/perfect-scrollbar";

// RC Tabs
@import "components/rc-tabs/rc-tabs";

// Loading Buttons
@import "components/loading-buttons/loading-buttons";

// Hamburger Button
@import "components/hamburgers/hamburgers";

// Drawer
@import "components/drawer/drawer";

// Notifications
@import "components/notifications/sweetalerts";
@import "components/notifications/toastify";

// Modals
@import "components/modals/rodal";

// Progress Bar
@import "components/progress-bar/progress-bar";

// Slick Carousel
@import "components/slick-carousel/slick/slick";

// Block loading
@import "components/block-loading/block-loading";

// Popovers & Tooltips
@import "components/popovers-tooltips/popovers-tooltips";

// Sticky Elements
@import "components/sticky-elements/sticky-elements";

// Calendar
@import "components/calendar/calendar";

// Splitters
@import "components/splitters/splitters";

// Tree View
@import "components/tree-view/tree-view";

// Tables
@import "components/tables/tables";

// Icons
@import "components/icons/linearicons";
@import "components/icons/p7icons";

// FORMS

@import "components/forms/datepicker/datepicker";
@import "components/forms/rangeslider/rangeslider";
@import "components/forms/multiselect/multiselect";
@import "components/forms/wysiwyg/wysiwyg";
@import "components/forms/toggle-switch/switch";
@import "components/forms/dropzone/dropzone";
@import "components/forms/typeahead/typeahead";

// REACT WIDGETS

@import "components/react-widgets/scss/react-widgets";

// DEMO

@import "demo-ui/demo";

// Responsive

@import "layout/responsive/responsive-base";

// Custom
.rdt_Table {
    border: unset;
}

.rdt_TableHead {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-left: 1px solid rgba(0, 0, 0, 0.12);
}

.rdt_TableBody {
    border-left: 1px solid rgba(0, 0, 0, 0.12);
}